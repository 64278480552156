<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import { storeToRefs } from 'pinia'

import CumulativePrice from '@/components/CumulativePrice/CumulativePrice.vue'
import GiftDetail from '@/components/Gift/GiftDetail.vue'
import Icon from '@/components/Icon/Icon.vue'
import PharmacyCertificates from '@/components/PharmacyCertificates'
import ProductDetailGallery from '@/components/Product/Detail/components/Gallery/ProductDetailGallery.vue'
import ProductDetailFieldList from '@/components/Product/Detail/components/ProductDetailFieldList.vue'
import ReturnableCarrierInfo from '@/components/Product/Detail/components/ReturnableCarrierInfo.vue'
import CShoppingListControl from '@/components/Product/Detail/components/ShoppingList/Control/ProductDetailShoppingListControl.vue'
import ProductDetailTabs from '@/components/Product/Detail/components/Tabs/ProductDetailTabs.vue'
import ProductVariants from '@/components/Product/Detail/components/Variants/ProductVariants.vue'
import ProductControlFavorite from '@/components/Product/ProductControlFavorite.vue'
import FutureDeliveryTag from '@/components/ProductItemBox/FutureDeliveryTag.vue'
import ProductAmount from '@/components/global/ProductAmount.vue'
import { BaseTag, Tooltip } from '@/componentsPure'
import NavBreadcrumb, { type NavBreadcrumbItem } from '@/componentsPure/NavBreadcrumb'
import useInteractionSource from '@/composables/useInteractionSource'
import { useProductItem, type Product } from '@/composables/useProductItem'
import useUser from '@/composables/useUser'
import { analyticsLoggerProductDetailLoaded } from '@/services/analytics/analyticsLogger'
import appConfig from '@/services/appConfig'
import { t, tc } from '@/services/translations'
import useCartStore from '@/store/pinia/useCartStore'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'
import useGiftsStore from '@/store/pinia/useGiftsStore'
import useProductDetailStore from '@/store/pinia/useProductDetailStore'
import useProductsStore from '@/store/pinia/useProductsStore'
import useUserInterfaceStore from '@/store/pinia/useUserInterfaceStore'
import localizePrice from '@/utils/localizePrice'
import { scrollTop } from '@/utils/scroll'

import CategoriesList from './components/CategoriesList.vue'
import useGenerateProductStructuredData from './useGenerateProductStructuredData'

type ProductDetailProps = {
  product: Product
  isPage?: boolean
}

const props = defineProps<ProductDetailProps>()

const cartStore = useCartStore()
const { cartGetters } = storeToRefs(cartStore)
const deviceDetectorStore = useDeviceDetectorStore()
const { getProduct } = useProductsStore()
const { currentVendorId } = storeToRefs(useUserInterfaceStore())
const { getGiftsByIds } = useGiftsStore()
const { isUserSignedIn } = useUser()

const productDetailStore = useProductDetailStore()
const { modalVendorContextId, productNotFound } = storeToRefs(productDetailStore)
const { closeModal } = productDetailStore

const interactionSource = useInteractionSource('ProductDetail')

const originalProductRef = computed(() => props.product)
const originalOrigin = computed(() => originalProductRef.value.origin)
const originalDetail = computed(() => originalOrigin.value.detail)

useGenerateProductStructuredData(originalProductRef)

// fetched full variant product (for detail tabs)
const selectedVariantProduct = ref<Product | null>(null)

// either original product or selected product
const currentProduct = computed(() => {
  return selectedVariantProduct.value ?? originalProductRef.value
})

const currentOrigin = computed(() => currentProduct.value.origin)
const currentDetail = computed(() => currentOrigin.value.detail)

const router = useRouter()

const giftsRef = ref<Element | null>(null)

analyticsLoggerProductDetailLoaded(interactionSource(), props.product)

// Computed
const {
  isSale,
  isCheaperPrice,
  cartProductQuantity,
  bestCheapPriceInfo,
  getPlannedStockDateWithDayPart,
  hasMatchingVendorId,
  hasProductGifts,
  hasProductGroups,
  getProductGroupsText,
  openDetail,
  openDetailRelated,
  hasBaseVendor: hasCurrentProductBaseVendor,
  carrierPrice,
  packagesPrice,
  isProductUnavailable,
} = useProductItem(currentProduct)

// Store getters

const { breakpointDownSm, breakpointUpMd } = storeToRefs(deviceDetectorStore)

const getBreadcrumbs = computed<NavBreadcrumbItem[]>(() => {
  return currentOrigin.value.breadcrumbs.length
    ? [
        {
          tag: 'a',
          name: t('general.brand'),
          url: router.resolve({ name: 'Home' }).href,
        } satisfies NavBreadcrumbItem,
        ...currentOrigin.value.breadcrumbs,
      ]
    : []
})

const hasProductTags = computed(() => {
  return !!currentOrigin.value.labels.length || !!currentOrigin.value.actionLabel
})

const hasIngredients = computed(
  () =>
    !!(
      currentDetail.value?.ingredients?.length ??
      currentDetail.value?.parameterGroups?.length ??
      currentDetail.value?.nutritionalValues
    ),
)

function handleActiveRouteLink() {
  const sameVendorId = currentVendorId.value === modalVendorContextId.value
  const sameBrandLink = router.currentRoute.value.path === originalDetail.value?.brand?.url
  if (sameVendorId || sameBrandLink) {
    closeModal()
    scrollTop()
  }
}

// variants related code

// do we have any relation products (it is determined by existence of associationCode)
const hasVariants = computed(() => !!originalDetail.value?.associationCode)

function handleSelectVariant(id: number) {
  selectedVariantProduct.value = getProduct(id)
}

const hasCumulativePrices = computed(() => currentProduct.value.origin.cumulativePrices.length)

const getCategoryTree = computed(() => currentOrigin.value.categoryTree)
</script>
<template>
  <article
    data-tid="product-detail"
    :aria-labelledby="`product-detail-${product.id}`">
    <NavBreadcrumb
      :items="getBreadcrumbs"
      :fetch-failed="productNotFound"
      class="my-2"
      separator="arrow"
      @click="handleActiveRouteLink" />
    <div class="d-flex mt-4 flex-wrap">
      <aside
        class="position-relative col-12 col-md-6"
        aria-hidden="true">
        <div
          v-if="hasProductGifts || isUserSignedIn"
          class="position-absolute ml-lg-4">
          <ProductControlFavorite
            v-if="isUserSignedIn"
            :product-id="currentProduct.id"
            :is-favorite="currentOrigin.favorite"
            highlight
            data-tid="product-detail__like_status"
            class="mt-2" />
          <button
            v-if="hasProductGifts"
            class="btn d-inline-block rounded-circle bg-primary p-0"
            :title="t('general.gift')"
            @click.prevent="giftsRef?.scrollIntoViewIfNeeded()">
            <Icon
              icon="gift"
              class="gift-icon" />
          </button>
        </div>

        <ProductDetailGallery
          :key="currentProduct.id"
          :unavailable="currentProduct.origin.maxInCart === 0"
          :photos="currentDetail?.photos.length ? currentDetail.photos : [currentOrigin.image]"
          :alt="currentOrigin.name">
          <template
            v-if="cartProductQuantity > 0"
            #bottom>
            <div
              class="text-align-center"
              data-tid="product-detail__overlay_price"
              v-html="
                t('product.detailOverlayPrice', {
                  price: localizePrice(cartProductQuantity * currentProduct.computed.price),
                  quantity: cartProductQuantity,
                })
              "></div>
            <div
              v-if="packagesPrice"
              data-tid="product-detail__overlay_packages_price"
              class="text-align-center"
              v-html="
                t('product.detailOverlayPackagesPrice', { price: localizePrice(packagesPrice) })
              " />

            <div
              v-if="carrierPrice"
              data-tid="product-detail__overlay_carrier_price"
              class="text-align-center"
              v-html="
                t('product.detailOverlayCarrierPrice', { price: localizePrice(carrierPrice) })
              " />
          </template>
        </ProductDetailGallery>
        <div
          v-if="hasProductTags || currentProduct.firstOrderDay.isValid()"
          class="product-tags">
          <FutureDeliveryTag
            v-if="isProductUnavailable"
            class="mb-1"
            :first-order-day="currentProduct.firstOrderDay" />
          <template v-if="currentOrigin.labels.length">
            <BaseTag
              v-for="(productTag, index) in currentOrigin.labels"
              :key="`product-tag${index}`"
              :modifier="productTag.styleKey ?? undefined"
              :data-tid="`modal-product-labels-${index}`"
              class="mb-1"
              size="big">
              {{ productTag.name }}
            </BaseTag>
          </template>
          <BaseTag
            v-if="currentOrigin.actionLabel"
            modifier="border red"
            size="big"
            data-tid="`modal-product-labels-consume`">
            {{ currentOrigin.actionLabel }}
          </BaseTag>
        </div>
      </aside>
      <div
        :class="[
          'col-12 col-md-6',
          {
            'text-center': breakpointDownSm,
          },
        ]">
        <h1
          :id="`product-detail-${currentProduct.id}`"
          class="product-name"
          :class="{
            'product-name--spacing':
              props.isPage && (cartGetters.getCartProductsCount || cartGetters.hasAdditionalOrders),
          }"
          data-tid="product-detail__product-name"
          v-text="currentProduct?.origin.name" />
        <div
          v-if="hasProductGroups"
          class="mt-n1 text-gray font-weight-bold mb-2"
          data-tid="product-detail__product-groups"
          v-text="getProductGroupsText" />
        <div
          :class="{
            'product-tag-row d-flex justify-content-between': breakpointUpMd,
          }">
          <BaseTag
            v-if="currentOrigin.productQuantity"
            modifier="gray"
            class="mb-md-2 mb-1"
            size="big">
            <span
              v-if="currentOrigin.productQuantity.prefix"
              class="text-2 text-gray mr-2"
              v-text="currentOrigin.productQuantity.prefix" />
            {{ currentOrigin.productQuantity.value }} {{ currentOrigin.productQuantity.unit }}
          </BaseTag>
          <CShoppingListControl
            v-if="isUserSignedIn"
            class="shopping-list-control mb-2"
            :product="currentProduct" />
        </div>
        <div v-if="hasCumulativePrices">
          <h3 class="multibuy-heading">{{ t('productDetail.multibuyHeading') }}</h3>
          <CumulativePrice
            :product="currentProduct"
            class="product-header-box product-header-box--flex"
            :class="
              hasCumulativePrices < 4 ? 'product-header-box--row' : 'product-header-box--column'
            " />
        </div>

        <div
          :class="[
            'product-header-box row no-gutters my-2',
            {
              'product-header-box--radius border-neutrals-10 border p-3': !breakpointDownSm,
              'justify-content-end': originalDetail?.unlisted,
            },
          ]">
          <div
            v-if="!originalDetail?.unlisted"
            class="col-12 col-md-6">
            <Tooltip
              text-color="white"
              tooltip-class="text-center"
              nowrap>
              <div :class="['d-flex', { 'justify-content-center': breakpointDownSm }]">
                <div
                  class="text-neutrals-70 text-nowrap pr-1"
                  v-html="bestCheapPriceInfo.label" />
                <s
                  v-if="bestCheapPriceInfo.price"
                  data-tid="product-box__crossed-price">
                  {{ localizePrice(bestCheapPriceInfo.price) }}
                </s>
              </div>
              <template
                v-if="
                  bestCheapPriceInfo.type === 'recommended' && appConfig.enableRecommendedPriceInfo
                "
                #message>
                <span v-html="t(`product.recommendedPriceInfo`)" />
              </template>
            </Tooltip>

            <strong
              :class="[
                'product-price text-8',
                {
                  'text-red': isCheaperPrice,
                },
              ]"
              data-tid="product-box__price"
              v-text="localizePrice(currentProduct.computed.price)" />
            <span
              class="text-neutrals-70"
              v-text="
                `${localizePrice(currentProduct.computed.pricePerUnit.price)}/${
                  currentProduct.computed.pricePerUnit.unit
                }`
              " />
          </div>
          <div class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
            <ProductAmount
              :product="currentProduct"
              :has-matching-vendor-id="hasMatchingVendorId"
              :unlisted="originalDetail?.unlisted"
              product-detail
              size="lg"
              class="my-2"
              @open-detail="openDetail" />
            <a
              v-if="originalOrigin.relatedProduct && !originalDetail?.unlisted"
              class="a-link-underline text-primary text-center"
              :href="originalOrigin.relatedProduct.url"
              @click.prevent="openDetailRelated"
              v-text="
                isSale
                  ? t('productDetail.relatedProductLink.default')
                  : t('productDetail.relatedProductLink.sale', {
                      price: localizePrice(originalOrigin.relatedProduct.price),
                    })
              " />
          </div>
        </div>

        <ReturnableCarrierInfo
          v-if="
            appConfig.enableReturnableCarrierInfo &&
            currentProduct.computed.quantity > 0 &&
            currentOrigin.returnableCarrier
          "
          :product="currentProduct" />

        <div
          v-if="hasVariants"
          class="d-flex mb-4 mt-2"
          :class="{ 'justify-content-center': breakpointDownSm }">
          <div
            v-if="hasVariants && currentDetail"
            class="variants">
            <ProductVariants
              v-if="currentDetail.associationCode"
              :product-id="product.id"
              :association-code="currentDetail.associationCode"
              @select="(id) => handleSelectVariant(id)" />
          </div>
        </div>
        <p
          v-if="currentOrigin.plannedStock && !productNotFound"
          class="text-red pr-lg-5"
          v-text="
            t('productDetail.plannedStock', {
              date: getPlannedStockDateWithDayPart,
            })
          " />

        <div
          v-if="productNotFound"
          class="mt-4">
          <p
            class="font-weight-bold"
            data-tid="product-no-results-title">
            {{ t('search.noResults.title') }}
          </p>
          <p
            class=""
            v-html="t('search.noResults.contact')" />
        </div>
        <div
          v-else
          data-tid="product-detail__origin">
          <p
            v-if="currentDetail?.brand"
            class="mb-0">
            <span
              class="font-weight-bold text-4"
              data-tid="product-detail__main-info"
              v-text="`${t('product.brand')}:`" />
            <RouterLink
              :to="currentDetail.brand.url"
              class="a-link-underline text-4"
              data-tid="product-detail__main-info__info_brand"
              @click="handleActiveRouteLink"
              v-text="currentDetail.brand.name" />
          </p>
          <p
            v-else-if="currentDetail?.brand === undefined"
            class="mb-0">
            <span class="a-placeholder-text mr-2">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span class="a-placeholder-text">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </p>

          <ProductDetailFieldList
            v-if="!!currentDetail?.origin.length"
            :items="currentDetail.origin"
            inline />

          <p
            v-if="currentDetail?.bestBefore?.guaranteed"
            data-tid="product-detail__bestBefore_top"
            class="mb-0">
            <span class="font-weight-bold">
              {{ t('productDetail.bestBefore.guaranteedShort') }}:
            </span>
            <span>
              {{
                currentDetail.bestBefore.guaranteed +
                '&nbsp;' +
                tc('productDetail.bestBefore.duration', currentDetail.bestBefore.guaranteed)
              }}
            </span>
          </p>
        </div>
        <p
          v-if="originalOrigin.returnablePackagePrice"
          class="mb-0">
          <span
            class="text-gray"
            v-text="`${t('productDetail.returnable')}:`" />
          <span v-text="localizePrice(currentOrigin.returnablePackagePrice)" />
        </p>
        <div
          v-if="currentDetail?.adultOnly"
          class="d-flex align-items-center justify-content-center justify-content-md-start mt-sm-1 my-2">
          <Icon
            icon="adult-only"
            group="product"
            class="adult-only-icon" />
          <div
            class="my-2 text-left"
            data-tid="product-detail__adult-only-text">
            <p
              :class="[
                'font-weight-bold mb-0',
                {
                  'text-4': !breakpointDownSm,
                },
              ]"
              v-text="t('product.adultOnly.productNotice')" />
            <p
              :class="[
                'mb-0',
                {
                  'text-2': breakpointDownSm,
                },
              ]"
              v-text="t('product.adultOnly.deliveryNotice')" />
          </div>
        </div>
        <div
          v-if="currentOrigin.pharmacyCertificate"
          class="mb-4 mt-3"
          data-tid="pharmacy-certificate">
          <PharmacyCertificates :certificates="[currentOrigin.pharmacyCertificate]" />
        </div>
      </div>
      <div
        v-if="originalOrigin.giftIds.length"
        ref="giftsRef"
        class="col-12 mt-2">
        <GiftDetail
          v-for="(gift, index) in getGiftsByIds(originalOrigin.giftIds)"
          :key="`gift-${index}`"
          :class="[
            'mx-lg-4',
            {
              'mb-1': originalOrigin.giftIds.length !== index + 1,
            },
          ]"
          :gift="gift" />
      </div>
    </div>
    <ProductDetailTabs
      v-if="!productNotFound"
      :key="currentProduct.id"
      class="mt-2"
      :product="currentProduct"
      :is-display-ingredients="hasIngredients"
      :is-display-similar-products="hasCurrentProductBaseVendor" />

    <CategoriesList
      v-if="getCategoryTree"
      :category-tree="getCategoryTree" />

    <footer
      v-if="currentDetail?.sapId"
      class="text-4 text-gray px-lg-5 px-2 pb-4 pt-2 text-right"
      v-text="currentDetail.sapId" />
  </article>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.product-not-found {
  display: grid;
  align-content: center;
  justify-content: space-around;
  min-height: 70vh;
  text-align: center;
}

.product-tag-row {
  max-width: 366px;
}

.product-header-box {
  max-width: 366px;

  @include media-breakpoint-down(sm) {
    margin-right: auto;
    margin-left: auto;
  }

  &--radius {
    border-radius: 10px;
  }

  &--flex {
    display: flex;
  }

  &--row {
    flex-direction: row-reverse;
  }

  &--column {
    flex-direction: column-reverse;
  }
}

.multibuy-heading {
  margin-top: 8px;
  font-size: get-font-size(4);
  font-weight: 600;
}

.product-name {
  @include make-font-scale(9);

  @include media-breakpoint-up(lg) {
    margin-right: 26px;

    &--spacing {
      margin-top: 20px;
    }
  }
}

.product-price {
  position: relative;
  display: block;
  margin: -6px 0;
}

.product-tags {
  position: absolute;
  top: 8px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.variants {
  width: 297px;

  @include media-breakpoint-up(lg) {
    width: 368px;
  }
}

// Bootstrap 4 like utility
.border-gray-lighter {
  border-color: color('gray-lighter') !important;
}

.shopping-list-control {
  max-width: 215px;

  @include media-breakpoint-down(sm) {
    width: 190px;
    margin-right: auto;
    margin-left: auto;
  }
}

.gift-icon {
  width: 27px;
  height: 27px;
  padding: 5px;
  color: color('white');
}

.adult-only-icon {
  width: 22px;
  height: 22px;
  margin-right: 9px;
  color: color('gray-darker');
}

.text-align-center {
  text-align: center;
}
</style>
