<script setup>
import { ref, computed } from 'vue'

import { Field as VeeField } from 'vee-validate'

const props = defineProps({
  /**
   * Can be used to explicitly set the initial (un)checked state.
   */
  modelValue: {
    type: Boolean,
    default: null,
  },
  /**
   * Can be used to force disabled state.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * What value this checkbox represents when checked and the containing form is submitted (to override HTML's default 'on').
   */
  formValue: {
    type: String,
    default: null,
  },
  /**
   * Sets a corresponding classname to the checkbox's label with a css rule 'white-space: nowrap'.
   */
  nowrap: {
    type: Boolean,
    default: false,
  },
  /**
   * When set true, the checkbox is rendered smaller and its label is closer to it.
   */
  small: {
    type: Boolean,
    default: false,
  },
  /**
   * Name for input, used in VeeField component, must be unique.
   */
  name: {
    type: String,
    default: null,
  },
  /**
   * Validation rules for VeeField component.
   */
  rules: {
    type: [String, Object, Function],
    default: null,
  },
})

const emit = defineEmits(['update:modelValue', 'focus'])

const checked = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const isFocused = ref(false)
const focus = computed({
  get() {
    return isFocused.value
  },
  set(newValue) {
    isFocused.value = newValue
    emit('focus', newValue)
  },
})
</script>

<template>
  <label
    :class="[
      'checkbox-label',
      {
        'checkbox-label--focus': focus,
        'checkbox-label--checked': checked,
        'checkbox-label--disabled': disabled,
        'checkbox-label--small': small,
        'text-nowrap': nowrap,
      },
    ]"
    @keyup.tab="focus = true">
    <VeeField
      v-if="rules"
      v-slot="slots"
      type="checkbox"
      :name="name"
      :rules="rules"
      :value="formValue">
      <input
        v-if="slots"
        v-model="checked"
        v-bind="slots.field"
        type="checkbox"
        :name="name"
        :disabled="disabled || null"
        class="checkbox-input"
        @focusout="focus = false" />
      <!-- @slot Content of the label with description what this checkbox actually means -->
      <slot />
      <span
        v-if="slots"
        class="validation-errors"
        v-text="slots.errors[0]" />
    </VeeField>

    <template v-else>
      <input
        v-model="checked"
        data-tid="form-checkbox__input"
        type="checkbox"
        :value="formValue"
        :disabled="disabled || null"
        class="checkbox-input"
        @focusout="focus = false" />
      <!-- @slot Content of the label with description what this checkbox actually means -->
      <slot />
    </template>
  </label>
</template>

<style src="../styles.scss" lang="scss" />
<style src="./styles.scss" lang="scss" scoped />
