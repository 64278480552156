import { computed } from 'vue'

import { useQuery, type UseQueryOptions } from '@tanstack/vue-query'

import type { FeatureFlag } from '@/constants/featureFlags'
import { growthbookQueryKey } from '@/constants/queryKeys'
import { growthbook } from '@/services/growthbook/growthbook'

// use 'as' because of bug in vuequery ts types, see https://github.com/TanStack/query/issues/6381
function useFeatureFlag<T>(key: FeatureFlag, defaultValue: T) {
  const query = useQuery({
    queryKey: growthbookQueryKey.featureFlag(key),
    queryFn: async () => growthbook.getFeatureValue(key, defaultValue),
    placeholderData: growthbook.getFeatureValue(key, defaultValue),
  } as UseQueryOptions<T, Error>)

  const data = computed(() => query.data.value ?? defaultValue)

  return { ...query, data }
}

export default useFeatureFlag
