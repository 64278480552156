<script setup lang="ts">
import { ref, computed } from 'vue'

import AImgResponsive from '@/components/stories/atoms/imgResponsive/ImgResponsive.vue'
import { resizer } from '@/services/resizer'
import { getMinMedia } from '@/services/responsiveMedia'
import { t } from '@/services/translations'
import useDeviceDetectorStore from '@/store/pinia/useDeviceDetectorStore'

type ProductDetailGalleryProps = {
  photos: string[]
  alt?: string
  unavailable?: boolean
}

const props = defineProps<ProductDetailGalleryProps>()

const deviceDetectorStore = useDeviceDetectorStore()

const openPhotoPosition = ref(0)

const hasThumbnails = computed(() => props.photos.length > 1)

const thresholdPixelRatio = 1.5
const getPreviewPicture = computed(() => {
  const previewPicture = props.photos[openPhotoPosition.value]
  return {
    srcset: [
      {
        url: resizer(previewPicture, 300, 200),
      },
      {
        url: resizer(previewPicture, 600, 400),
        pixelRatio: thresholdPixelRatio,
      },
    ],
    variants: [
      {
        media: getMinMedia('lg'),
        srcset: [
          {
            url: resizer(previewPicture, 430, 400),
          },
          {
            url: resizer(previewPicture, 860, 800),
            pixelRatio: thresholdPixelRatio,
          },
        ],
      },
    ],
  }
})
const getThumbnails = computed(() =>
  props.photos.map((photo) => ({
    srcset: [
      {
        url: resizer(photo, 66, 66),
      },
      {
        url: resizer(photo, 132, 132),
        pixelRatio: thresholdPixelRatio,
      },
    ],
  })),
)
</script>

<template>
  <div>
    <div :class="{ 'main-img-wrapper': props.unavailable || !!$slots.bottom }">
      <AImgResponsive
        :class="['image-wrapper', { unavailable: props.unavailable }]"
        data-tid="product-detail__product-image"
        :srcset="getPreviewPicture.srcset"
        :variants="getPreviewPicture.variants"
        :alt="alt"
        fetch-priority />
      <div
        v-if="props.unavailable"
        class="product-unavailable-text">
        {{ t('productDetail.productSoldOut') }}
      </div>

      <div
        v-if="$slots.bottom"
        data-tid="product-detail__product-overlay"
        class="product-cumulative-prices">
        <slot name="bottom" />
      </div>
    </div>

    <div
      v-if="!deviceDetectorStore.breakpointDownSm && hasThumbnails"
      class="d-flex flex-wrap my-2 ml-lg-4">
      <button
        v-for="(thumbnail, index) in getThumbnails"
        :key="index"
        :class="[
          'thumbnail',
          {
            'thumbnail--active': openPhotoPosition === index,
          },
        ]"
        @click="openPhotoPosition = index">
        <AImgResponsive
          class="w-100 h-100"
          :srcset="thumbnail.srcset"
          :alt="alt"
          lazyload />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/legacy' as *;

.main-img-wrapper {
  display: grid;
}

.product-unavailable-text {
  z-index: 1;
  place-self: center;
  padding: 16px;
  color: color('neutrals-80');
  background-color: rgba(255, 255, 255, 70%);
}

.product-cumulative-prices {
  z-index: 1;
  place-self: center;
  padding: 16px;
  color: color('white');
  background-color: color('contrast-green');
  border-radius: var(--borderRadiusMedium);
}

.product-unavailable-text,
.image-wrapper,
.product-cumulative-prices {
  grid-area: 1/-1;
}

.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  @include media-breakpoint-up(lg) {
    height: 400px;
  }

  &.unavailable {
    opacity: 0.2;
  }

  :deep(img) {
    max-height: 100%;
  }
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
}

.thumbnail {
  width: 80px;
  height: 80px;
  padding: 7px;
  background: none;
  border: 1px solid transparent;
  border-radius: 0;

  &--active {
    border-color: color('gray-light');
  }
}
</style>
