import dayjs from 'dayjs'
import { defineActions, defineGetters, defineModule, defineMutations } from 'direct-vuex'
import debounce from 'lodash-es/debounce'

import { invalidatePreviewTimeslots } from '@/components/AddressSelector/usePreviewTimeslots'
import useCheckHaveReloadPage from '@/composables/address/useCheckHaveReloadPage'
import useReturnableCarrier from '@/composables/product/useReturnableCarrier'
import type { InteractionSourceRaw } from '@/composables/useInteractionSource'
import type { Product } from '@/composables/useProductItem'
import featureFlags from '@/constants/featureFlags'
import { type VendorID, Vendors } from '@/constants/vendors'
import router from '@/router'
import {
  analyticsLoggerAddressChosen,
  analyticsLoggerAlternativeReplaceableProductChosen,
  analyticsLoggerDeleteCart,
  analyticsLoggerRedirectUrl,
  analyticsLoggerSetCart,
  analyticsLoggerSetCartInteraction,
} from '@/services/analytics/analyticsLogger'
import { redirectCookieKey } from '@/services/analytics/gtm/redirectUrl'
import type { ProductForAnalytics } from '@/services/analytics/gtm/utils/transformProductToGtm'
import { fetchAbortRequestByName } from '@/services/api/emitSilenceFetch'
import {
  frontApiDeleteCart,
  frontApiGetCart,
  frontApiPostCartRejectProductGift,
  frontApiPutCartProduct,
  frontApiPutCartProductsFromOrder,
  frontApiPutCartReplacements,
} from '@/services/api/front/cart'
import type { CartPrices } from '@/services/api/front/cartTypes'
import { frontApiPutCheckoutTransport } from '@/services/api/front/checkout'
import appConfig from '@/services/appConfig'
import { getCookie, setCookie } from '@/services/cookies'
import { growthbook } from '@/services/growthbook/growthbook'
import { toastError, toastSuccess } from '@/services/toast'
import { t } from '@/services/translations'
import { moduleActionContext, moduleGetterContext } from '@/store'
import { isProductPartiallySoldOut, isProductSoldOut } from '@/store/utils/productAvailability'
import { calculateTotalPrice } from '@/store/utils/totalPrice'
import { getTotalProductsDiscount } from '@/store/utils/totalProductsDiscount'
import { findObjectByKey } from '@/utils/findObjectByKey'

import useAddressStore from '../pinia/useAddressStore'
import useCartAlertsStore from '../pinia/useCartAlertsStore'
import useCartStore from '../pinia/useCartStore'
import type {
  AdditionalOrders,
  CartGift,
  CartProduct,
  CartState,
  CurrentlyReplacedProduct,
  PartialCartProduct,
  ProductInCategories,
  ProductInteractionSource,
  SelectedTimeslot,
} from '../pinia/useCartStore/cartTypes'
import useGiftsStore from '../pinia/useGiftsStore'
import useOrdersStore from '../pinia/useOrdersStore'
import useProductsStore from '../pinia/useProductsStore'
import useTimeslotsStore from '../pinia/useTimeslotsStore'
import useUserInterfaceStore from '../pinia/useUserInterfaceStore'

type ProductQuantity = { id: number; quantity: number; carrierQuantity?: number | null }

type ProductQuantityProps = {
  product: ProductQuantity
  interactionSource?: InteractionSourceRaw[]
  increasing?: boolean
  carrierChange?: boolean
  step?: number
}

const INTERACTION_SOURCE_NAME_PRODUCT_REPLACEABLE = 'ProductReplaceable'
const INTERACTION_SOURCE_NAME_PRODUCT_LIMITED = 'ProductLimited'
const INTERACTION_SOURCE_ELEMENT_BUTTON_REPLACE = 'button_replace'

const debounceListPatchCartProduct = {}

const productQuantityDebouncedMap = new Map<number, number>()

const cartProductStructure = {
  id: null,
  quantity: null,
  carrierQuantity: null,
  useCarrier: true,
  carrierChecked: false,
}

function _mapProduct(
  id: CartProduct['id'],
  quantity: CartProduct['quantity'] | null = null,
  carrierQuantity: CartProduct['carrierQuantity'] = null,
) {
  const cartProduct: PartialCartProduct = { id }

  if (quantity !== null) {
    cartProduct.quantity = quantity
  }

  if (carrierQuantity !== null) {
    cartProduct.carrierQuantity = carrierQuantity
  }

  cartProduct.useCarrier = !!carrierQuantity

  return cartProduct
}

function _getNextCategoryProductIndexes(getters, id: number) {
  let indexes: any = null
  let removedProductIndex = null

  getters.getCartProducts.some((cartProduct, index) => {
    if (
      removedProductIndex !== null &&
      getters.getCartProductFullData(cartProduct.id).origin.mainCategory.id ===
        getters.getCartProductFullData(id).origin.mainCategory.id
    ) {
      indexes = {
        from: index,
        to: removedProductIndex,
      }

      return true
    }

    if (id === cartProduct.id) {
      removedProductIndex = index
    }

    return false
  })

  return indexes
}

function _getAddedGifts(getters, gifts) {
  const ids: any[] = []

  gifts.forEach((currentGift) => {
    const foundGift = getters.getCartGifts.find((gift) => gift.id === currentGift.id)

    if (!foundGift || foundGift.quantity < currentGift.quantity) {
      ids.push(currentGift.id)
    }
  })

  return ids
}

function _getCartGiftFullData(cartGift) {
  const { getGiftById } = useGiftsStore()
  return {
    ...getGiftById(cartGift.id),
    ...cartGift,
  }
}

const _debounceRefuseReplacementApi = debounce((isRefused) => {
  frontApiPutCartReplacements(isRefused)
}, 500)

async function putCheckoutTransport(timeslot: SelectedTimeslot) {
  const {
    data: { cartPrices },
  } = await frontApiPutCheckoutTransport(timeslot)

  return cartPrices
}

const debouncedPutCheckoutTransport = debounce(putCheckoutTransport, 1000, {
  leading: true,
  trailing: true,
})

const cartState: CartState = {
  cart_loaded: false,
  cart_products: [],
  cart_gifts: [],
  currently_replaced_product: null,
  show_replacement: false,
  refuse_replacement: false,
  interaction_sources: [],
  selected_timeslot: null,
  cart_prices: {
    credit: 0,
    payment: 0,
    products: 0,
    returnablePackaging: 0,
    tip: 0,
    total: 0,
    transport: 0,
    voucher: 0,
    weightedProductDeposite: 0,
  },
  additional_orders: [],
  hasMarketPlaceProducts: false,
  isFreeDelivery: false,
}

const cartMutations = defineMutations<CartState>()({
  SET_CART_PRODUCTS(state, products: CartProduct[]) {
    state.cart_products = products
  },
  SET_CART_PRODUCTS_DEFAULT_QUANTITY(state) {
    state.cart_products.forEach((product: CartProduct) => {
      product.quantity = 0
    })
  },
  ADD_CART_PRODUCT(state, product: PartialCartProduct) {
    state.cart_products.unshift({
      ...cartProductStructure,
      ...product,
    })
  },
  UPDATE_CART_PRODUCT(state, productNew: PartialCartProduct) {
    const productOld = findObjectByKey(state.cart_products, productNew.id)?.item
    Object.assign(productOld ?? {}, productNew)
  },
  REMOVE_CART_PRODUCT(state, id: number) {
    state.cart_products = state.cart_products.filter((product) => product.id !== id)
  },
  MOVE_CART_PRODUCT(state, indexes: { from: number; to: number }) {
    const { from, to } = indexes || {}
    if (from !== undefined && to !== undefined) {
      state.cart_products.splice(to, 0, state.cart_products.splice(from, 1)[0])
    }
  },
  SET_CART_LOADED(state, status: boolean) {
    state.cart_loaded = status
  },
  SET_CART_GIFTS(state, gifts: CartGift[]) {
    state.cart_gifts = gifts
  },
  SET_CART_GIFT_QUANTITY(state, payload: { id: number; quantity: number }) {
    const { id, quantity } = payload
    const gift = state.cart_gifts.find((giftItem) => giftItem.id === id)
    if (gift) {
      gift.quantity = quantity
    }
  },
  REMOVE_CART_GIFT(state, id: number) {
    state.cart_gifts = state.cart_gifts.filter((gift) => gift.id !== id)
  },
  SET_SHOW_REPLACEMENT(state, status: boolean) {
    state.show_replacement = status
  },
  SET_REFUSE_REPLACEMENT(state, status: boolean) {
    state.refuse_replacement = status
  },
  SET_SELECTED_TIMESLOT(state, newTimeslot: SelectedTimeslot | null) {
    state.selected_timeslot = newTimeslot
  },
  UPDATE_CART_PRICES(state, cartPrices: CartPrices) {
    for (const [key, value] of Object.entries(cartPrices)) {
      cartPrices[key] = Math.abs(value)
    }
    state.cart_prices = { ...state.cart_prices, ...cartPrices }
  },
  SET_INTERACTION_SOURCE(state, newInteraction: ProductInteractionSource) {
    const productInteraction = state.interaction_sources.find(
      (product) => product.id === newInteraction.id,
    )

    if (productInteraction) {
      Object.assign(productInteraction, newInteraction)
    } else {
      state.interaction_sources.push(newInteraction)
    }
  },
  SET_CURRENTLY_REPLACED_PRODUCT(state, product: CurrentlyReplacedProduct | null) {
    state.currently_replaced_product = product
  },
  SET_ADDITIONAL_ORDERS(state, orders: AdditionalOrders[]) {
    state.additional_orders = orders
  },
  SET_HAS_MARKETPLACE_PRODUCTS(state, value: boolean) {
    state.hasMarketPlaceProducts = value
  },
  SET_FREE_DELIVERY(state, value: boolean) {
    state.isFreeDelivery = value
  },
})

const cartGetters = defineGetters<CartState>()({
  getSelectedTimeslot(state) {
    return state.selected_timeslot
  },

  getCartProducts(state): CartProduct[] {
    return state.cart_products
  },
  getCartProductsByIds(state): (productIds: number[]) => CartProduct[] {
    return (productIds: number[]) => {
      const products = state.cart_products
      const productsLength = products.length
      const productIdsLength = productIds.length
      const foundProducts: CartProduct[] = []
      let findProductIterator = 0

      for (let i = 0; i < productsLength; i++) {
        const positionInProductIds = productIds.indexOf(products[i].id)

        if (positionInProductIds >= 0) {
          foundProducts[positionInProductIds] = products[i]
          findProductIterator++
        }

        if (productIdsLength === findProductIterator) {
          break
        }
      }

      return foundProducts.filter(Object)
    }
  },
  getCartProduct(state): (productId: number) => CartProduct | undefined {
    return (productId) => findObjectByKey(state.cart_products, productId)?.item
  },
  getCartProductsFullData(state): Product[] {
    const { getProducts } = useProductsStore()
    return getProducts(state.cart_products.map((cartProduct: CartProduct) => cartProduct.id))
  },
  getCartProductFullData(...args): (id: number) => Product | undefined {
    const { getters } = cartGetterContext(args)
    const returnFn = (id: number) =>
      getters.getCartProductsFullData.find((product: Product) => product.id === id)

    return returnFn
  },
  getCartProductsInCategories(...args): ProductInCategories[] {
    const { getters } = cartGetterContext(args)
    const productsInCategories: ProductInCategories[] = []

    getters.getCartProductsFullData.forEach((product) => {
      if (isProductSoldOut(product) || isProductPartiallySoldOut(product)) {
        return
      }

      const categoryWithProducts = productsInCategories.find((item) => {
        return (
          item.category.id === product.origin.mainCategory.id &&
          item.vendorId === product.origin.vendorId
        )
      })

      if (categoryWithProducts) {
        categoryWithProducts.products.push(product)
      } else {
        productsInCategories.push({
          category: product.origin.mainCategory,
          products: [product],
          vendorId: product.origin.vendorId as VendorID,
        })
      }
    })

    const userInterfaceStore = useUserInterfaceStore()
    // sorts greater vendorIds to the end, i.e. base vendor categories first, unless non-base vendor forces reverse sorting
    const reverse = userInterfaceStore.currentVendorId === Vendors.BASE ? 1 : -1
    productsInCategories.sort((a, b) => (a.vendorId - b.vendorId) * reverse)

    return productsInCategories
  },
  getCartReplaceableProducts(...args): {
    soldOut: Product[]
    partiallySoldOut: Product[]
  } {
    const { getters } = cartGetterContext(args)
    const soldOut: Product[] = []
    const partiallySoldOut: Product[] = []

    getters.getCartProductsFullData.forEach((product) => {
      if (isProductSoldOut(product)) {
        soldOut.push(product)
        return
      }
      if (isProductPartiallySoldOut(product)) {
        partiallySoldOut.push(product)
      }
    })

    const userInterfaceStore = useUserInterfaceStore()
    // sorts greater vendorIds to the end, i.e. base vendor products first, unless non-base vendor forces reverse sorting
    const reverse = userInterfaceStore.currentVendorId === Vendors.BASE ? 1 : -1
    soldOut.sort((a, b) => (a.origin.vendorId - b.origin.vendorId) * reverse)
    partiallySoldOut.sort((a, b) => (a.origin.vendorId - b.origin.vendorId) * reverse)

    return {
      soldOut,
      partiallySoldOut,
    }
  },
  getCartProductsSubsetFullData(...args): (ids: number[]) => Product[] {
    const { getters } = cartGetterContext(args)
    return (ids) =>
      getters.getCartProductsFullData.filter((product) => ids.indexOf(product.id) !== -1)
  },
  getCartProductsCount(state) {
    return state.cart_products.length
  },
  getCartProductSumCount(state) {
    return state.cart_products.reduce((sum, product) => sum + (product.quantity ?? 0), 0)
  },
  getCartReplaceableProductsCount(...args): number {
    const { getters } = cartGetterContext(args)
    const products = getters.getCartProductsFullData.filter(
      (product) => isProductSoldOut(product) || isProductPartiallySoldOut(product),
    ).length

    return products
  },
  getCartProductsPrice(...args): number {
    const { getters } = cartGetterContext(args)
    return calculateTotalPrice(getters.getCartProductsFullData)
  },

  /**
   * Returns price without products with eLicence === true
   */
  getCartProductsRealPrice(...args): number {
    const { getters } = cartGetterContext(args)
    return calculateTotalPrice(getters.getCartProductsFullData, {
      includeElicence: false,
    })
  },

  getCartProductsTotalPrice(...args): (products: readonly Pick<Product, 'id'>[]) => number {
    const { getters } = cartGetterContext(args)
    return (products: readonly Pick<Product, 'id'>[]) =>
      calculateTotalPrice(
        getters.getCartProductsSubsetFullData(products.map((product) => product.id)),
        {
          includeReturnables: true,
        },
      )
  },
  getCartTotalPrice(...args): number {
    const { getters } = cartGetterContext(args)
    return calculateTotalPrice(getters.getCartProductsFullData, {
      includeReturnables: true,
    })
  },
  getCartTotalPriceWithDeposits(...args): number {
    const { getters } = cartGetterContext(args)
    return calculateTotalPrice(getters.getCartProductsFullData, {
      includeReturnables: true,
      includeDeposits: true,
    })
  },
  getCartTotalDiscount(...args): number {
    const { getters } = cartGetterContext(args)
    return getTotalProductsDiscount(getters.getCartProductsFullData as Product[])
  },
  getCalcCarriersQuantity(
    ...args
  ): (productId: number, productQuantity?: number | null) => number | undefined {
    const { getProductOrThrow } = useProductsStore()
    const { getters } = cartGetterContext(args)
    return (productId, productQuantity = null) => {
      const product = getProductOrThrow(productId)
      const carrier = getProductOrThrow(productId).origin.returnableCarrier
      const { inputCarrierValue } = useReturnableCarrier(product)
      let calculatedProductQuantity: number | null = productQuantity

      if (!carrier) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      calculatedProductQuantity ||= getters.getCartProduct(productId)?.quantity as number
      const filledCarriers = ~~(calculatedProductQuantity / carrier.capacity)
      const partiallyFilledCarrier =
        calculatedProductQuantity % carrier.capacity >= carrier.quantityBreakpoint ? 1 : 0
      const newQuantity = filledCarriers + partiallyFilledCarrier

      // we need to add one more carrier (for these conditions), bcs cartStore sends the wrong value (one less then we need)
      if (
        inputCarrierValue.value === true &&
        !partiallyFilledCarrier &&
        calculatedProductQuantity % carrier.capacity
      ) {
        return newQuantity + 1
      }

      return newQuantity > carrier.maxQuantity ? carrier.maxQuantity : newQuantity
    }
  },
  getCartGifts(...args): CartGift[] {
    const { state } = cartGetterContext(args)
    const gifts: CartGift[] = []

    state.cart_gifts.forEach((cartGift) => {
      if (cartGift.quantity) {
        gifts.push(_getCartGiftFullData(cartGift))
      }
    })

    return gifts
  },
  getCartGift(...args): (id: number) => CartGift | undefined {
    const { state } = cartGetterContext(args)
    return (id) => {
      const cartGift = state.cart_gifts.find((gift) => gift.id === id)

      if (cartGift) {
        return _getCartGiftFullData(cartGift)
      }
    }
  },
  isCartLoaded(state) {
    return state.cart_loaded
  },
  isReplacementsRefusalVisible(state) {
    return state.show_replacement
  },
  isRefusedReplacement(state) {
    return state.refuse_replacement
  },
  /* If user is allowed to navigate to checkout */
  isAllowedCheckout(...args): boolean {
    const { getters } = cartGetterContext(args)

    return (
      !!getters.getCartProductsCount ||
      (getters.hasAdditionalOrders && !!getters.getCartProductsCount)
    )
  },
  getInteractionSource(state): (id: number) => InteractionSourceRaw[] | undefined {
    return (id) => state.interaction_sources.find((product) => product.id === id)?.interactionSource
  },
  getCurrentlyReplacedProduct(state) {
    return state.currently_replaced_product
  },
  getCartCheckoutPrices(state) {
    return state.cart_prices
  },
  getCartAdditionalOrders(state): AdditionalOrders[] {
    // @todo Type probably with dayjs transform
    return state.additional_orders.map((order) => ({
      ...order,
      closeDateTime: dayjs(order.closeDateTime),
      deliverySlot: {
        ...order.deliverySlot,
        date: dayjs(order.deliverySlot.date),
      },
    }))
  },
  /**
   * Returns true if cart contains at least one product with eLicence === true
   */
  hasELicenceOrReturnablesProducts(...args): boolean {
    const { getters } = cartGetterContext(args)
    return getters.getCartProductsFullData.some(
      (product) =>
        product.origin.eLicence ||
        product.origin.returnablePackagePrice > 0 ||
        (product.origin.returnableCarrier?.price ?? 0) > 0,
    )
  },
  hasAdditionalOrders(state) {
    return state.additional_orders.length > 0
  },
  hasMoreAdditionalOrders(state) {
    return state.additional_orders.length > 1
  },
  hasBaseVendorProduct(...args): boolean {
    const { getters } = cartGetterContext(args)
    return getters.getCartProductsFullData.some(
      (product) => product.origin.vendorId === Vendors.BASE,
    )
  },
  hasNonBaseVendorProduct(...args): boolean {
    const { getters } = cartGetterContext(args)
    return getters.getCartProductsFullData.some(
      (product) => product.origin.vendorId !== Vendors.BASE,
    )
  },
  hasMarketPlaceProducts(state) {
    return state.hasMarketPlaceProducts
  },
  hasFreeDelivery(state) {
    return state.isFreeDelivery
  },
})

const cartActions = defineActions({
  setCartProduct(context, newProduct: PartialCartProduct) {
    const { syncProductComputedWithCartProduct } = useProductsStore()
    const { commit, state, getters } = cartActionContext(context)
    const currentRouteName = router.currentRoute.value.name
    if (newProduct.quantity === 0) {
      commit.REMOVE_CART_PRODUCT(newProduct.id)
    } else if (state.cart_products.some((product) => product.id === newProduct.id)) {
      commit.UPDATE_CART_PRODUCT(newProduct)
    } else {
      commit.ADD_CART_PRODUCT(newProduct)

      if (getters.getCartProductsCount === 1) {
        const { autoOpenCartPreviewDesktop } = useUserInterfaceStore()
        autoOpenCartPreviewDesktop(currentRouteName)
      }
    }

    syncProductComputedWithCartProduct(newProduct)
  },
  async initCart(context) {
    const { commit, dispatch } = cartActionContext(context)
    const { setProducts } = useProductsStore()

    const {
      data: {
        shoppingCartProducts,
        cartPrices,
        gifts,
        deliveryAddress,
        showReplacementCheckbox,
        refuseReplacements,
        transport,
        transportPrices,
        additionalOrders,
        hasMarketplaceProducts,
      },
    } = await frontApiGetCart()

    analyticsLoggerAddressChosen(deliveryAddress)

    const redirectFrom = getCookie(redirectCookieKey)
    if (redirectFrom) {
      analyticsLoggerRedirectUrl(redirectFrom)
      setCookie(redirectCookieKey, '', 0)
    }

    // same value as window.config.freeDelivery
    commit.SET_FREE_DELIVERY(transportPrices.freeDelivery)

    const products = shoppingCartProducts.map((product) => ({
      quantity: product.quantity,
      returnableCarrier: product.returnableCarrier,
      weightedProductDeposit: product.weightedProductDeposit,
      // @todo type fix - remove as any
      ...(product.product as any),
    }))

    if (transport) {
      const { start, end, ...rest } = transport

      commit.SET_SELECTED_TIMESLOT({
        start: dayjs(start),
        end: dayjs(end),
        ...rest,
      })
    }

    if (products.length) {
      const cartProducts = products.map<CartProduct>((product) => {
        if (product.returnableCarrier?.quantity > 0) {
          const { setCarrierValue } = useReturnableCarrier(product)
          setCarrierValue(true)
        }
        return {
          ...(cartProductStructure as unknown as CartProduct),
          ..._mapProduct(product.id, product.quantity, product.returnableCarrier?.quantity),
        }
      })

      commit.SET_CART_PRODUCTS(cartProducts)
      setProducts(products)
    }

    dispatch.updateCartPrices(cartPrices)

    if (gifts.length) {
      dispatch.setCartGifts(gifts)
    }

    if (deliveryAddress) {
      const { initUserAddress } = useAddressStore()
      const { lat, lng } = deliveryAddress.positionGps
      initUserAddress({
        ...deliveryAddress,
        positionGps: lat && lng ? { lat, lng } : null,
      })
      invalidatePreviewTimeslots()
    }

    commit.SET_SHOW_REPLACEMENT(showReplacementCheckbox)
    commit.SET_REFUSE_REPLACEMENT(refuseReplacements)
    commit.SET_ADDITIONAL_ORDERS(additionalOrders)
    commit.SET_HAS_MARKETPLACE_PRODUCTS(hasMarketplaceProducts)
    commit.SET_CART_LOADED(true)

    analyticsLoggerSetCart(router.currentRoute.value.name)
  },
  setCartGifts(context, gifts) {
    const { setGifts } = useGiftsStore()
    const { commit } = cartActionContext(context)

    setGifts(gifts)
    commit.SET_CART_GIFTS(gifts.map(({ id, quantity }) => ({ id, quantity })))
  },
  async removeCartGift(context, id) {
    const { commit, getters } = cartActionContext(context)
    const currentQuantity = getters.getCartGift(id).quantity
    commit.SET_CART_GIFT_QUANTITY({ id, quantity: 0 })

    try {
      const {
        data: { status },
      } = await frontApiPostCartRejectProductGift(id)

      if (status !== 'ok') {
        throw new Error()
      }

      commit.REMOVE_CART_GIFT(id)
    } catch (error) {
      commit.SET_CART_GIFT_QUANTITY({ id, quantity: currentQuantity })
    }
  },
  setRefuseReplacement(context, isRefused: boolean) {
    const { commit } = cartActionContext(context)
    commit.SET_REFUSE_REPLACEMENT(isRefused)
    _debounceRefuseReplacementApi(isRefused)
  },
  updateCartPrices(context, cartPrices) {
    const { commit } = cartActionContext(context)
    commit.UPDATE_CART_PRICES(cartPrices)
  },
  async setSelectedTimeslot(context, timeslot: SelectedTimeslot) {
    const { commit } = cartActionContext(context)

    commit.SET_SELECTED_TIMESLOT(timeslot)

    const cartPrices = await debouncedPutCheckoutTransport(timeslot)

    if (!cartPrices) return
    commit.UPDATE_CART_PRICES(cartPrices)
  },
  setCartQuantityToMaxAvailable(context, id) {
    const { dispatch, getters } = cartActionContext(context)
    const quantity = getters.getCartProductFullData(id)?.origin?.maxInCart
    if (typeof quantity === 'number') {
      dispatch.setCartProductQuantity({
        product: {
          id,
          quantity,
        },
      })
    }
  },
  setCartProductQuantity(
    context,
    {
      product,
      interactionSource = [],
      increasing = true,
      carrierChange,
      step,
    }: ProductQuantityProps,
  ) {
    const { setCurrentlyChangedProduct } = useCartAlertsStore()
    const { markCartTimeslotsStale } = useTimeslotsStore()
    const { getProductOrThrow, syncProductDom } = useProductsStore()
    const { commit, dispatch, getters } = cartActionContext(context)

    // product quantity increase is allowed only when its vendor matches the vendor that the customer is currently browsing
    const productVendorId = getProductOrThrow(product.id).origin.vendorId
    const userInterfaceStore = useUserInterfaceStore()
    const currentVendorId = userInterfaceStore.currentVendorId

    if (increasing && productVendorId !== currentVendorId) {
      return
    }

    if (!productQuantityDebouncedMap.has(product.id)) {
      const cartProduct = getters.getCartProduct(product.id)
      productQuantityDebouncedMap.set(product.id, cartProduct?.quantity ?? 0)
    }

    commit.SET_INTERACTION_SOURCE({
      id: product.id,
      interactionSource,
    })

    const { removeProductFromMap } = useReturnableCarrier(getProductOrThrow(product.id))

    const {
      id: currentlyReplacedProductId,
      isSoldOut: isCurrentlyReplacedProductSoldOut,
      hasLimitedDelivery: hasCurrentlyReplacedProductLimitedDelivery,
    } = getters.getCurrentlyReplacedProduct ?? {}

    if (currentlyReplacedProductId) {
      analyticsLoggerAlternativeReplaceableProductChosen(product.id)
    }

    if (!!isCurrentlyReplacedProductSoldOut || !!hasCurrentlyReplacedProductLimitedDelivery) {
      dispatch.clearCurrentlyReplacedProduct()
      hasCurrentlyReplacedProductLimitedDelivery && markCartTimeslotsStale()

      const removeCartProductInteractionSource = [
        {
          name: isCurrentlyReplacedProductSoldOut
            ? INTERACTION_SOURCE_NAME_PRODUCT_REPLACEABLE
            : INTERACTION_SOURCE_NAME_PRODUCT_LIMITED,
          element: INTERACTION_SOURCE_ELEMENT_BUTTON_REPLACE,
        },
      ]

      const interactionSourceElements = interactionSource.filter((source) => source.element)
      if (interactionSourceElements.length) {
        removeCartProductInteractionSource[0].name = interactionSourceElements[0].name
      }

      dispatch.removeCartProduct({
        productId: currentlyReplacedProductId,
        interactionSource: removeCartProductInteractionSource,
      })
    }

    if (product.quantity > 0) {
      // default behaviour for adding product to the cart is to open address modal after adding the first product if address is not set
      const openAddressWhenAddThirdToCart = growthbook.getFeatureValue(
        featureFlags.openAddressWhenAddThirdToCart,
        false,
      )

      // variation only for CZ and customer with featureflag, address modal should be opened when adding the third product to the cart, so condition is current product in the cart is min 2
      const featureSpecialCondition =
        openAddressWhenAddThirdToCart &&
        (getters.getCartProductSumCount >= 2 || product.quantity > 2) &&
        appConfig.openAddressWhenAddThirdToCart

      if (increasing && (!openAddressWhenAddThirdToCart || featureSpecialCondition)) {
        const { checkAddressAndHoldProductIfNeeded } = useCartStore()
        const isHoldingProduct = checkAddressAndHoldProductIfNeeded({
          product,
          interactionSource,
          increasing,
        })
        if (isHoldingProduct) return
      }

      const maxInCart = getProductOrThrow(product.id).origin.maxInCart
      const cartProduct = getters.getCartProduct(product.id)

      if (product.quantity > maxInCart) {
        product.quantity = maxInCart
      }

      if (cartProduct?.useCarrier) {
        product.carrierQuantity = getters.getCalcCarriersQuantity(product.id, product.quantity)
      }

      dispatch.setCartProduct(product)
      setCurrentlyChangedProduct(product.id)
    } else {
      commit.MOVE_CART_PRODUCT(_getNextCategoryProductIndexes(getters, product.id))
      dispatch.setCartProduct(product)
      removeProductFromMap()
    }

    syncProductDom(product.id)
    dispatch.sendToCartApiDebounce({
      productId: product.id,
      increasing,
      carrierChange,
      step,
    })
  },
  sendToCartApiDebounce(context, { productId, increasing, carrierChange, step }) {
    const { dispatch } = cartActionContext(context)
    debounceListPatchCartProduct[productId]?.cancel()
    debounceListPatchCartProduct[productId] = debounce(() => {
      dispatch.sendToCartApi({
        productId,
        increasing,
        carrierChange,
        step,
      })
    }, appConfig.productQuantityDebouncing)

    fetchAbortRequestByName('setProduct' + productId)
    debounceListPatchCartProduct[productId]()
  },
  async sendToCartApi(context, { productId, increasing, carrierChange, step }) {
    const { setCurrentlyAddedGifts } = useCartAlertsStore()
    const timeslotsStore = useTimeslotsStore()
    const { getProductOrThrow, setProduct, syncProductDom } = useProductsStore()

    const { commit, dispatch, getters } = cartActionContext(context)
    const product = getProductOrThrow(productId)

    const { inputCarrierValue, setCarrierMultibuy } = useReturnableCarrier(product)

    try {
      const quantity = product.cart?.quantity || 0
      const carrierQuantity =
        product.cart && Number.isInteger(product.cart.carrierQuantity)
          ? !!product.cart.carrierQuantity || inputCarrierValue.value
          : null

      const {
        data: {
          shoppingCartProduct: {
            product: productFresh,
            quantity: quantityFresh,
            returnableCarrier,
            weightedProductDeposit,
          },
          gifts,
        },
      } = await frontApiPutCartProduct(productId, quantity, carrierQuantity)

      setProduct({
        ...productFresh,
        quantity: quantityFresh,
        returnableCarrier,
        weightedProductDeposit,
      })

      const addedGiftsIds = _getAddedGifts(getters, gifts)
      if (addedGiftsIds.length) {
        setCurrentlyAddedGifts(addedGiftsIds)
      }
      dispatch.setCartGifts(gifts)

      if (!productFresh) {
        commit.REMOVE_CART_PRODUCT(productId)
      }

      if (quantity !== null && quantity !== quantityFresh) {
        dispatch.setCartProduct(_mapProduct(productId, quantityFresh, null))
      }

      if (
        carrierQuantity !== null &&
        product.cart.carrierQuantity !== returnableCarrier?.quantity
      ) {
        dispatch.setCartProduct(_mapProduct(productId, null, returnableCarrier?.quantity ?? 0))
      }

      if (quantity > productFresh.maxInCart) {
        dispatch.setErrorsAfterSendToCartApi({
          name: product.origin.name,
          quantity: productFresh.maxInCart,
          unit: productFresh.unit,
        })
      }

      setCarrierMultibuy(returnableCarrier, step)
    } catch (error) {
      toastError(t('cart.alert.general'))
      dispatch.revertCartProduct(product)
    } finally {
      syncProductDom(product.id)

      const originalQuantity = productQuantityDebouncedMap.get(productId) ?? 0
      analyticsLoggerSetCartInteraction(
        increasing,
        [
          {
            productId,
            carrierChange,
            originalQuantity,
          },
        ],
        getters.getInteractionSource,
      )
      productQuantityDebouncedMap.delete(productId)

      const checkHaveReloadPage = useCheckHaveReloadPage()
      checkHaveReloadPage()

      // Timeslots refreshing needed in case the manipulated product has affected their availability (e.g. after removing ProductLimited)
      if (timeslotsStore.cartTimeslotsStale) {
        timeslotsStore.fetchCartTimeslots()
      }
      // invalidates preview slot data (it fetches after data is used)
      invalidatePreviewTimeslots()
    }
  },
  setErrorsAfterSendToCartApi(_, { name, quantity, unit }) {
    const { setCartAlert } = useCartAlertsStore()
    setCartAlert(
      quantity && unit
        ? {
            messageKey: 'cart.alert.maxInCartLowered',
            params: {
              name,
              quantity,
              unit,
            },
          }
        : {
            messageKey: 'cart.alert.productUnavailable',
            params: {
              name,
            },
          },
    )
  },
  async setCartProductsFromOrder(context, { orderId, interactionSource = [] }) {
    const { dispatch, getters } = cartActionContext(context)
    const { setProducts } = useProductsStore()
    try {
      // needed for the quantity comparison in case all the order products fail to add in fully demanded quantity
      const originalCartProducts = getters.getCartProducts.map((item) =>
        _mapProduct(item.id, item.quantity),
      )
      const ordersStore = useOrdersStore()
      const storeOrderProducts = ordersStore.getOrder?.orderProducts
      // needed primary for positively setting order products into UI, secondary for the aforementioned comparison
      const orderProducts = storeOrderProducts?.map((item) => ({
        id: item.product.id,
        quantity: Math.min(
          item.deliveredQuantity + item.product.computed.quantity,
          item.product.origin.maxInCart,
        ),
        vendorId: item.product.origin.vendorId,
      }))

      // set the same products into cart in the same quantities
      orderProducts?.forEach((orderProduct) => {
        if (orderProduct.quantity > 0 && orderProduct.vendorId === Vendors.BASE) {
          dispatch.setCartProduct(orderProduct)
        }
      })

      // validate and inform
      let atLeastOneProductQuantityIncreased = true
      const {
        data: { products },
      } = await frontApiPutCartProductsFromOrder(orderId)

      const lackedProducts = products?.map((product) => ({
        quantity: product.quantity,
        returnableCarrier: product.returnableCarrier,
        ...product.product,
      }))
      if (lackedProducts?.length) {
        setProducts(lackedProducts)
        lackedProducts.forEach((lackedProduct) => {
          const isProductAvailablePharmacy =
            lackedProduct.vendorId === Vendors.PHARMACY && lackedProduct.maxInCart
          toastError(
            t(
              `profile.detailOrder.setCartProductsFromOrder.${
                isProductAvailablePharmacy ? 'pharmacy' : 'noFreeInStock'
              }`,
              { productName: lackedProduct.name },
            ),
          )
          dispatch.setCartProduct(_mapProduct(lackedProduct.id, lackedProduct.quantity))
        })

        // in case all the order products fail to add in fully demanded quantity, detect whether at least one product increased its quantity
        if (lackedProducts.length === orderProducts?.length) {
          atLeastOneProductQuantityIncreased = lackedProducts.some((lackedProduct) => {
            const originalCartProduct = originalCartProducts.find(
              (product) => product.id === lackedProduct.id,
            )
            return lackedProduct.quantity > (originalCartProduct?.quantity ?? 0)
          })
        }
      }
      // product only added to cart filtered after validation on BE (for example pharmacy items cant be added to cart from addAll button)
      const realAddedProducts =
        storeOrderProducts?.filter((storeOrderProduct) => {
          return !lackedProducts?.some(
            (lackedProduct) => lackedProduct.id === storeOrderProduct.product.id,
          )
        }) ?? []

      const productsForAnalytics: ProductForAnalytics[] = realAddedProducts.map((product) => {
        return {
          productId: product.product.id,
          carrierChange: !!product.returnableCarrier, // vratne lahve
          originalQuantity: product.product.computed.quantity,
        }
      })

      analyticsLoggerSetCartInteraction(true, productsForAnalytics, interactionSource)

      if (atLeastOneProductQuantityIncreased) {
        toastSuccess(t('profile.detailOrder.setCartProductsFromOrder.success'))
      }
    } catch (error) {
      toastError(t('profile.detailOrder.setCartProductsFromOrder.error'))
    }
  },
  revertCartProduct(context, product) {
    const { dispatch, getters, commit } = cartActionContext(context)
    const cartProduct = getters.getCartProduct(product.id)
    let carrierQuantity = product?.origin?.returnableCarrier?.quantity || null

    if (product.origin.quantity) {
      carrierQuantity =
        carrierQuantity === null && cartProduct?.carrierQuantity ? 0 : carrierQuantity
      dispatch.setCartProduct(_mapProduct(product.id, product.origin.quantity, carrierQuantity))
    } else {
      commit.REMOVE_CART_PRODUCT(product.id)
    }
  },
  removeCartProduct(context, { productId, interactionSource }) {
    const { dispatch } = cartActionContext(context)
    dispatch.setCartProductQuantity({
      product: {
        id: productId,
        quantity: 0,
      },
      interactionSource,
      increasing: false,
    })
  },
  useReturnableCarrier(context, { productId, interactionSource }) {
    const { commit, dispatch, getters } = cartActionContext(context)
    if (interactionSource) {
      commit.SET_INTERACTION_SOURCE({
        id: productId,
        interactionSource,
      })
    }
    dispatch.setCartProduct({
      ..._mapProduct(productId, null, getters.getCalcCarriersQuantity(productId)),
      useCarrier: true,
    })
    dispatch.sendToCartApiDebounce({
      productId,
      increasing: true,
      carrierChange: true,
    })
  },
  removeReturnableCarrier(context, { productId, interactionSource }) {
    const { commit, dispatch } = cartActionContext(context)
    if (interactionSource) {
      commit.SET_INTERACTION_SOURCE({
        id: productId,
        interactionSource,
      })
    }

    dispatch.setCartProduct({
      ..._mapProduct(productId, null, 0),
      useCarrier: false,
    })
    dispatch.sendToCartApiDebounce({
      productId,
      carrierChange: true,
    })
  },
  async clearCart(context, interactionSource) {
    const { syncProductsWithCartProducts } = useProductsStore()
    const { commit, getters } = cartActionContext(context)
    await frontApiDeleteCart()

    analyticsLoggerDeleteCart(
      interactionSource,
      getters.getCartProductsFullData,
      getters.getCartProductsTotalPrice(getters.getCartProductsFullData),
    )

    commit.SET_CART_PRODUCTS_DEFAULT_QUANTITY()
    syncProductsWithCartProducts(getters.getCartProducts)

    commit.SET_CART_PRODUCTS([])
  },
  setCurrentlyReplacedProduct(context, { id, isSoldOut, hasLimitedDelivery }) {
    const { commit } = cartActionContext(context)
    commit.SET_CURRENTLY_REPLACED_PRODUCT({
      id,
      isSoldOut,
      hasLimitedDelivery,
    })
  },
  clearCurrentlyReplacedProduct(context) {
    const { commit } = cartActionContext(context)
    commit.SET_CURRENTLY_REPLACED_PRODUCT(null)
  },
})

const cart = defineModule({
  namespaced: true,
  state: cartState,
  mutations: cartMutations,
  getters: cartGetters,
  actions: cartActions,
})

export default cart
export type {
  CartProduct,
  SelectedTimeslot,
  CartGift,
  AdditionalOrders,
  CartState,
  PartialCartProduct,
  ProductQuantityProps,
}

const cartGetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, cart)
const cartActionContext = (context: any) => moduleActionContext(context, cart)
