<script setup lang="ts">
import { computed } from 'vue'

import useFeatureFlag from '@/composables/useFeatureFlag'
import { type Product } from '@/composables/useProductItem'
import featureFlags from '@/constants/featureFlags'
import appConfig from '@/services/appConfig'

import ProductItemBox from './ProductItemBox.vue'
import ProductItemBoxB from './ProductItemBoxB.variant.vue'

type ProductItemBoxProps = {
  product: Product | null
  order?: number
  separated?: boolean
}

defineProps<ProductItemBoxProps>()
const { data: featureEnabledSettings } = useFeatureFlag(featureFlags.productBoxA, false)
const featureEnabled = computed(() => featureEnabledSettings.value && appConfig.domain === 'cz')
</script>

<template>
  <component
    :is="featureEnabled ? ProductItemBoxB : ProductItemBox"
    v-bind="{ ...$attrs, ...$props }" />
</template>
