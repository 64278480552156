const featureFlags = {
  openAddressWhenAddThirdToCart: 'open-address-when-add-third-to-cart',
  ecoSlot: 'eco-slot',
  productBoxA: 'product-box-a',
  alternativeBenefits: 'alternative-benefits',
} as const

type FeatureFlag = (typeof featureFlags)[keyof typeof featureFlags]

export default featureFlags
export type { FeatureFlag }
