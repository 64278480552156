import { ref, type Plugin } from 'vue'

import { GrowthBook, configureCache, type Experiment, type Result } from '@growthbook/growthbook'

import { ensureConfig } from '@/composables/useConfig'
import { growthbookQueryKey } from '@/constants/queryKeys'

import { analyticsLoggerExperimentViewed } from '../analytics/analyticsLogger'
import { analyticsCookiesValues, getCookieConsents } from '../privacy'
import queryClient from '../queryClient'
import growthbookConfig from './config'
import growthbookKey from './growthbookKey'

type GrowthbookPluginParams = {
  enabled: boolean
}

type GrowthbookAttributes = {
  userId: number | null
  warehouseId: number
  subDestinationId: number | null
}

const isGrowthbookReady = ref(false)

configureCache({
  // The localStorage key the cache will be stored under
  cacheKey: 'gbFeaturesCache',
  // Consider features stale after this much time (60 seconds default)
  staleTTL: 1000 * 60,
  // Cached features older than this will be ignored (24 hours default)
  maxAge: 1000 * 60 * 60 * 24,
  // For Remote Eval only - limit the number of cache entries (~1 entry per user)
  maxEntries: 10,
  // When `false`, we add a `visibilitychange` listener to disable SSE when the page is idle
  disableIdleStreams: false,
  // Consider a page "idle" when it is hidden for this long (default 20 seconds)
  idleStreamInterval: 20000,
  // Set to `true` to completely disable both in-memory and persistent caching
  disableCache: false,
})

const growthbook = new GrowthBook({ ...growthbookConfig, trackingCallback })

function trackingCallback(experiment: Experiment<any>, result: Result<any>) {
  analyticsLoggerExperimentViewed(experiment.key, result.key)
}

function isConsentGranted() {
  const consentPreferences = getCookieConsents()
  return consentPreferences?.analytics_storage === analyticsCookiesValues.granted
}

function setGrowthbookAttributes(attributes: GrowthbookAttributes) {
  return growthbook.setAttributes({
    id: isConsentGranted() ? attributes.userId : null,
    warehouseId: attributes.warehouseId,
    subdestination: attributes.subDestinationId,
  })
}

const growthbookPlugin: Plugin<GrowthbookPluginParams> = {
  install(app, options) {
    app.provide(growthbookKey, isGrowthbookReady)

    if (!options.enabled) {
      isGrowthbookReady.value = true
      return
    }
    ensureConfig()
      .then((config) =>
        setGrowthbookAttributes({
          userId: config.user.luigisboxUserId,
          warehouseId: config.cart.destinationId,
          subDestinationId: config.cart.subDestinationId,
        }),
      )
      .then(() => growthbook.init({ streaming: true, timeout: 3000 }))
      .then(() => {
        growthbook.setRenderer(() => {
          queryClient.invalidateQueries({
            queryKey: growthbookQueryKey.all,
          })
        })
      })
      .catch((error) => {
        console.error('Failed to initialize GrowthBook:', error)
      })
      .finally(() => {
        isGrowthbookReady.value = true
      })
  },
}

export default growthbookPlugin
export { growthbook, setGrowthbookAttributes }
